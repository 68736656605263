import request from '@/utils/request'

//---------商品类目---------

//获取商户列表
export function getShopsPage(data) {
  return request({
    url: '/MA_mall_shop/getShopsPage',
    method: 'get',
    params: data
  })
}

//获取商户详情
export function getShopInfo(data) {
  return request({
    url: '/MA_mall_shop/getShopInfo',
    method: 'get',
    params: data
  })
}


//新增商户
export function addShop(data) {
  return request({
    url: '/MA_mall_shop/addShop',
    method: 'post',
    data
  })
}


//修改商户
export function updateShop(data) {
  return request({
    url: '/MA_mall_shop/updateShop',
    method: 'post',
    data
  })
}

//删除商户
export function deleteShop(data) {
  return request({
    url: '/MA_mall_shop/deleteShop',
    method: 'get',
    params: data
  })
}



//恢复商户
export function resumeShop(data) {
  return request({
    url: '/MA_mall_shop/resumeShop',
    method: 'get',
    params: data
  })
}

//暂停商户
export function suspendShop(data) {
  return request({
    url: '/MA_mall_shop/suspendShop',
    method: 'get',
    params: data
  })
}

//重置密码
export function resetPassword(data) {
  return request({
    url: '/MA_mall_shop/resetPassword',
    method: 'get',
    params: data
  })
}




//查询商户类型
export function getDictItem(data) {
  return request({
    url: '/sysdict/getDictItem',
    method: 'get',
    params: data
  })
}

//新增/修改字典类型项
export function updateDictItem(data) {
  return request({
    url: '/sysdict/addOrModify',
    method: 'get',
    params: data
  })
}

//删除字典类型项
export function delDictItem(data) {
  return request({
    url: '/sysdict/delete',
    method: 'get',
    params: data
  })
}

