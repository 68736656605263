import request from '@/utils/request'

//---------订单管理---------
//查询类目
export function orderList(data) {
  return request({
    url: '/MA_mall_order/orderList',
    method: 'get',
    params: data
  })
}


//提货
export function deliver(data) {
  return request({
    url: '/MA_mall_order/deliver',
    method: 'get',
    params: data
  })
}

//导出
export function exportOrder(data) {
  return request({
    url: '/MA_mall_order/exportList',
    method: 'post',
    params: data,
    responseType: "blob",
  })
}




//---------扫码支付记录---------
//查询列表
export function scanpayList(data) {
  return request({
    url: '/MA_mall_order/scanCodePayList',
    method: 'get',
    params: data
  })
}


//导出
export function exportScanpayRecord(data) {
  return request({
    url: '/MA_mall_order/exportScanCodeList',
    method: 'post',
    params: data,
    responseType: "blob",
  })
}