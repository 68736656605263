<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="兑换码">
          <el-input v-model="formInline.code" clearable placeholder="请输入兑换码"></el-input>
        </el-form-item>
        <el-form-item label="商户名称">
          <el-select v-model="formInline.shopId" filterable clearable placeholder="请选择商户">
            <el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
				</el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formInline.type" clearable placeholder="请选择兑换码类型">
            <el-option label="全部" value=""></el-option>
            <el-option label="限时卡" :value="1"></el-option>
            <el-option label="限次卡" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formInline.status" clearable placeholder="请选择兑换码状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="未使用" :value="1"></el-option>
            <el-option label="已上架" :value="2"></el-option>
            <el-option label="已兑换" :value="3"></el-option>
            <el-option label="已作废" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="addRow">新增</el-button>
          <el-button type="primary" @click="addMoreBlackFn()">批量导入兑换码</el-button>
          <a href="/redeemcode.xlsx" target="_blank" style="margin-left: 10px">
            <el-button type="primary" icon="el-icon-download">导入模板下载</el-button>
          </a>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="兑换码"
        prop="code">
      </el-table-column>
      <el-table-column
        label="商户名称"
        prop="shop_name">
      </el-table-column>
      <el-table-column
        label="商品名称"
        prop="product_name">
      </el-table-column>
      <el-table-column
        label="类型"
        prop="type">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? '限时卡' : '限次卡' }}
        </template>
      </el-table-column>
      
      <el-table-column
        label="使用期限/次数"
        prop="num">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? scope.row.num + '天' : scope.row.num + '次' }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="status_name">
      </el-table-column>
      <el-table-column
          label="操作"
          width="240">
          <template slot-scope="scope">
            <el-button @click="detailRow(scope.row)" type="default" size="mini">详情</el-button>
            <el-button :disabled="scope.row.status != 1" @click="editRow(scope.row)" type="primary" size="mini">修改</el-button>
            <el-button :disabled="scope.row.status != 1" @click="deleteRow(scope.row)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
    </el-table>

    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>

    <!-- 新增兑换码信息 -->
    <el-dialog class="dialogForm" title="新增兑换码信息" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" style="width: 94%;">
        <!-- <el-form-item label="兑换码" prop="code">
          <el-input v-model="form.code" clearable placeholder="请输入兑换码"></el-input>
        </el-form-item> -->
        <el-form-item
          v-for="(code, index) in form.codes"
          :label="'兑换码' + (index==0?'': index+1)"
          :key="code.key"
          :prop="'codes.' + index + '.value'"
          :rules="{
            required: true, message: '兑换码不能为空', trigger: 'blur'
          }"
        >
          <el-input v-model="code.value" style="width: calc(100% - 150px); margin-right: 10px;"></el-input>
          <el-button v-if="index != 0" @click.prevent="removeCode(code)">删除</el-button>
          <el-button v-else @click="addCode">添加兑换码</el-button>
        </el-form-item>
        <el-form-item label="商户" prop="shopId">
          <el-select
          style="width: 100%"
            clearable
            filterable
            v-model="form.shopId"
            collapse-tags
            placeholder="请选择商户">
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select style="width: 100%" v-model="form.type" clearable placeholder="请选择兑换码类型">
            <el-option label="限时卡" :value="1"></el-option>
            <el-option label="限次卡" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="form.num" type="number" clearable placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item label="批号" prop="batch">
          <el-input v-model="form.batch" clearable placeholder="请输入批号"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :disabled="loading" type="primary" @click="submitForm('form')"> {{ loading?'结算中':'确认提交'}}</el-button>
        <el-button @click="resetForm('form')">重 置</el-button>
      </div>
    </el-dialog>


    <!-- 修改兑换码信息 -->
    <el-dialog class="dialogForm" title="修改兑换码信息" :close-on-click-modal="false" :visible.sync="editDialogFormVisible" width="500px">
      <el-form :model="editform" :rules="rules2" ref="editform" label-width="100px" style="width: 94%;">
        <el-form-item label="兑换码" prop="code">
          <el-input v-model="editform.code" clearable placeholder="请输入兑换码"></el-input>
        </el-form-item>
        <el-form-item label="商户" prop="shopId">
          <el-select
            style="width: 100%"
            filterable
            clearable
            v-model="editform.shopId"
            collapse-tags
            placeholder="请选择商户">
            <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select style="width: 100%" v-model="editform.type" clearable placeholder="请选择兑换码类型">
            <el-option label="限时卡" :value="1"></el-option>
            <el-option label="限次卡" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量" prop="num">
          <el-input v-model="editform.num" type="number" clearable placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item label="批号" prop="batch">
          <el-input v-model="editform.batch" clearable placeholder="请输入批号"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select style="width: 100%" v-model="editform.status" clearable placeholder="请选择兑换码状态">
            <el-option label="未使用" :value="1"></el-option>
            <el-option label="已上架" :value="2"></el-option>
            <el-option label="已兑换" :value="3"></el-option>
            <el-option label="已作废" :value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :disabled="loading" type="primary" @click="updateSubmitForm('editform')"> {{ loading?'结算中':'确认修改'}}</el-button>
      </div>
    </el-dialog>


    <el-dialog
      title="兑换码详情"
      :visible.sync="dialogVisible"
      width="800px">
      <div class="detailinfobox">
        <el-row :gutter="20">
          <el-col :span="6"><div>兑换码</div></el-col>
          <el-col :span="18"><div>{{ codeDetailInfo.code }}</div></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6"><div>商户名称</div></el-col>
          <el-col :span="6">
            <div v-if="codeDetailInfo.shop_name">{{ codeDetailInfo.shop_name }}</div>
            <div v-else>-</div>
          </el-col>
          <el-col :span="6"><div>商品名称</div></el-col>
          <el-col :span="6">
            <div v-if="codeDetailInfo.product_name">{{ codeDetailInfo.product_name }}</div>
            <div v-else>-</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6"><div>类型</div></el-col>
          <el-col :span="6"><div v-if="codeDetailInfo.type_name">{{ codeDetailInfo.type_name }}</div><div v-else>-</div></el-col>
          <el-col :span="6"><div>数量</div></el-col>
          <el-col :span="6"><div>{{ codeDetailInfo.num }}</div></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6"><div>状态</div></el-col>
          <el-col :span="6"><div v-if="codeDetailInfo.status_name">{{ codeDetailInfo.status_name }}</div><div v-else>-</div></el-col>
          <el-col :span="6"><div>批号</div></el-col>
          <el-col :span="6"><div v-if="codeDetailInfo.batch">{{ codeDetailInfo.batch }}</div><div v-else>-</div></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6"><div>用户账号id</div></el-col>
          <el-col :span="18"><div v-if="codeDetailInfo.accountuuid">{{ codeDetailInfo.accountuuid }}</div><div v-else>-</div></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6"><div>订单id</div></el-col>
          <el-col :span="6"><div v-if="codeDetailInfo.order_goods_id">{{ codeDetailInfo.order_goods_id }}</div><div v-else>-</div></el-col>
          <el-col :span="6"><div>下单时间</div></el-col>
          <el-col :span="6"><div v-if="codeDetailInfo.buy_time">{{ codeDetailInfo.buy_time }}</div><div v-else>-</div></el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

 	<el-dialog title="导入" :visible.sync="dialogFormVisible1" width="600px" :before-close="onBeforeClose" :close-on-click-modal="false">
      <el-form :model="form"  ref="form" class="formbox" label-width="130px">
        <el-form-item label="导入文件" prop="file">
          <el-upload ref="upload" class="upload-demo" action="#" :limit="1" accept=".xlsx"
            :on-change="handleChange" :on-remove="handleRemove" :auto-upload="false">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitImportForm('form')">确 定</el-button>
		<el-button @click="cancleFn1">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { getCodePage, addCodes, updateCode, deleteCode, detailCode,importData } from '@/api/redeemCode'
import { scanpayList, exportScanpayRecord } from '@/api/orderManage'
import { sureMessageBox }　from '@/utils/common'
import { getShopsPage } from '@/api/merchantManage'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      formInline: {
        code: '',
        shopId: '',
        type: '',
        status: null
      },
      dateTimes: [],
      tableData: [],
      tableDataExport: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      shopList: [],
      dialogFormVisible: false,
      form: {
        shopId: '',
        code: '',
        batch: '',
        type: '',
        num: null,
        codes: [{
          value: ''
        }],
      },
      editDialogFormVisible: false,
      editform: {
        shopId: '',
        code: '',
        batch: '',
        type: '',
        num: null,
        id: null,
        status: null,
      },
      loading: false,
      rules: {
        shopId: [
          { required: true, message: '请选择商户', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入兑换码', trigger: 'blur' }
        ],
        batch: [
          { required: true, message: '请输入批号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ]
      },
      rules2: {
        shopId: [
          { required: true, message: '请选择商户', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入兑换码', trigger: 'blur' }
        ],
        batch: [
          { required: true, message: '请输入批号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请输入数量', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ]

      },
      dialogVisible: false,
	  dialogFormVisible1: false,
      codeDetailInfo: {}
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getShoper();
    this.getCodeList();
  },
  computed:{},
  methods:{
    //获取兑换码列表
    getCodeList(){
      getCodePage({
        shopId: this.formInline.shopId,
        type: this.formInline.type,
        status: this.formInline.status,
        code: this.formInline.code,
        pageSize: this.pageSize,
        page: this.currentPage
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getCodeList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCodeList();
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    //搜索
    onSearch() {
      console.log('submit!');
      this.currentPage = 1;
      this.getCodeList();
    },
    //新增的时候，添加多个兑换码
    addCode() {
      this.form.codes.push({
        value: '',
        key: Date.now()
      });
    },
    //删除本地兑换码
    removeCode(item) {
      let index = this.form.codes.indexOf(item)
      if (index !== -1) {
        this.form.codes.splice(index, 1)
      }
    },
    
    //添加兑换码
    addRow() {
      this.dialogFormVisible = true;
      this.resetForm('form');
    },
     //添加兑换码提交
     submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let codes = [];
          this.form.codes.map(item=>{
            codes.push(item.value)
          })
          addCodes({
            redeem: {
              shopId: this.form.shopId,
              batch: this.form.batch,
              type: this.form.type,
              num: this.form.num,
              status: 1 //新增时，默认未使用
            },
            codes: codes
          }).then( res => {
            this.loading = false;
            if(res.result == 200) {
              this.$message.success('操作成功');
              this.onSearch();
              this.dialogFormVisible = false;
            } else {
              this.$message.warning(res.description)
            }
          })
        }
      })
    },
    //重置新增兑换码表单
    resetForm(formName) {
      this.form = {
        shopId: '',
        code: '',
        batch: '',
        type: '',
        num: null,
        codes: [{
          value: ''
        }],
      }
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.loading = false;
    },

    //编辑兑换码信息
    editRow(row){
      this.editDialogFormVisible = true;
      this.resetEditForm('editform');
      this.editform = {
        shopId: row.shop_id,
        code: row.code,
        batch: row.batch,
        type: row.type,
        num: row.num,
        status: row.status,
        id: row.id
      }
    },

    //添加兑换码提交
    updateSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          updateCode({
            redeem: {
              id: this.editform.id,
              shopId: this.editform.shopId,
              batch: this.editform.batch,
              type: this.editform.type,
              num: this.editform.num,
              status: this.editform.status,
              code: this.editform.code
            }
          }).then( res => {
            this.loading = false;
            if(res.result == 200) {
              this.$message.success('操作成功');
              this.onSearch();
              this.editDialogFormVisible = false;
            } else {
              this.$message.warning(res.description)
            }
          })
        }
      })
    },

    //重置编辑兑换码表单
    resetEditForm(formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.loading = false;
    },

    //删除兑换码
    deleteRow(row){
      console.log(row)
      sureMessageBox('是否确认删除该条数据？').then(res=>{
        deleteCode({
          id: row.id
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.onSearch();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },

    //查看兑换码详情
    detailRow(row){
      detailCode({
          id: row.id
        }).then(res=>{
          if(res.result == 200){
            console.log(res.detail)
            this.dialogVisible = true;
            this.codeDetailInfo = res.detail;
          }else{
            this.$message.warning(res.description)
          }
        })
    },
    //获取商户列表
    getShoper() {
      getShopsPage({
        pageSize: '100000',
        pageNum: '1'
      }).then(res=>{
				if(res.result==200){
					this.shopList = res.detail.list;
          this.shopList.unshift({
            name: '全部',
            id: ' '
          })
				}else{
					this.$message.error(res.description)
				}
			})
    },
    
	onBeforeClose(done){
	  done();
	  this.cancleFn1();
	},
	handleChange(file, fileList) {
	  console.log(fileList);
	  this.form.file = fileList[0].raw;
	},
	handleRemove(file, fileList) {
	  console.log(fileList);
	  this.form.file = "";
	},
	addMoreBlackFn() {
	  this.handleRemove();
	  this.dialogFormVisible1 = true;
	},
	cancleFn1(){
		this.dialogFormVisible1 = false;
		this.form.file = "";
		this.$refs.upload.clearFiles();
	},
	submitImportForm(formName) {
	  this.$refs[formName].validate((valid) => {
	    if (valid) {
	        let formData = new FormData();
	        formData.append('file', this.form.file);
	        importData(formData, {
	        }).then(res=>{
	          if(res.result == 200){
	            let detail = res.detail;
	            this.$confirm(detail, '提示', {
	              confirmButtonText: '确定',
	              cancelButtonText: '取消',
	            }).then(() => {
	              this.onSearch();
				  this.cancleFn1();
	            }).catch(() => {
				  this.onSearch();
				  this.cancleFn1();
	            })
	          } else {
	            this.$message.error(res.description);
	          }
	        })
	    } else {
	      return false;
	    }
	  });
	},
    
  }
}
</script>

<style lang="scss">
.detailinfobox{
  padding: 0 30px;
  .el-row{
    .el-col{
      padding: 15px;
      border: 1px solid #eee;
      margin-bottom: -1px;
    }
    >div:nth-child(2n+1){
      text-align: right;
      background: #f0f0f0;
    }
  }
}
  .searchbox {
    margin: 20px 10px;
  }
  .order-table-expand {
    font-size: 0;
  }
  .order-table-expand label {
    width: 110px;
    color: #99a9bf;
  }
  .order-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  
  .pagebox{
    margin: 20px 0;
    text-align: right;
  }

  .goodimg{
    cursor: pointer;
    img{
      height: 100px;
      width: auto;
    }
  }
</style>