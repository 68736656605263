import request from '@/utils/request'

//---------商品类目---------

//获取兑换码列表
export function getCodePage(data) {
  return request({
    url: '/MA_mall_redeemcode/queryPage',
    method: 'get',
    params: data
  })
}



//新增兑换码
export function addCodes(data) {
  return request({
    url: '/MA_mall_redeemcode/addOrModify',
    method: 'post',
    data
  })
}

//修改兑换码
export function updateCode(data) {
  return request({
    url: '/MA_mall_redeemcode/addOrModify',
    method: 'post',
    data
  })
}

//兑换码详情
export function detailCode(data) {
  return request({
    url: '/MA_mall_redeemcode/detail',
    method: 'get',
    params: data
  })
}

//删除兑换码
export function deleteCode(data) {
  return request({
    url: '/MA_mall_redeemcode/delete',
    method: 'get',
    params: data
  })
}



//关联兑换码
export function bingProduct(data) {
  return request({
    url: '/MA_mall_redeemcode/bingProduct',
    method: 'post',
    params: data
  })
}

//导入
export function importData(form, data) {
  return request({
    url: '/MA_mall_redeemcode/importRedeemCode',
    method: 'post',
    params: data,
	data: form
  })
}


